import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { themes } from '@backstage/theme';
import { myTheme } from './theme/myTheme';

import { UnifiedThemeProvider } from '@backstage/theme';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

/*
  Github Integration
*/
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

import {
  DetailedArticlesPage,
  FAQsPage,
  InformationPage,
  LandingPage,
} from '@internal/backstage-plugin-home';
import { EventsPage } from '@internal/backstage-plugin-events';
import { RbacPage } from '@janus-idp/backstage-plugin-rbac';
import { policyEntityReadPermission } from '@janus-idp/backstage-plugin-rbac-common';

import { ErrorcatalogListPage } from '@internal/backstage-plugin-errorcatalog-list';
import {
  ServiceCatalogCreatePage,
  ServiceCatalogListPage,
  ServiceRequestDetailViewPage,
} from '@internal/backstage-plugin-service-catalog';

import {
  ServiceRequestPage,
  ServiceRequestEditPage,
  ServiceRequestListPage,
  ServiceRequestDetailPage,
} from '@internal/backstage-plugin-service-request';

import {
  EventGridPage,
  EventGridEditPage,
  EventGridListPage,
  //EventGridDetailPage,
} from '@internal/backstage-plugin-event-grid';

import { VendorCreationPage } from '@internal/backstage-plugin-vendor';
import { SystemCreationPage } from '@internal/backstage-plugin-system';

import { TeamAdministrationPage } from '@internal/backstage-plugin-team-administration';

import { QetaPage } from '@drodil/backstage-plugin-qeta';
import {
  ContactUsPage,
  DataModelPage,
  CIEOverviewPage,
} from '@internal/backstage-plugin-documentation/src/plugin';

const microsoftAuthProvider: SignInProviderConfig = {
  id: 'microsoft-auth-provider',
  title: 'Azure',
  message: 'Sign in using Azure',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        // auto
        provider={microsoftAuthProvider}
      />
    ),
  },
  themes: [
    {
      id: 'my-theme',
      title: 'NEOM Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={myTheme} children={children} />
      ),
    },
    {
      id: 'default-dark',
      title: 'Default Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.dark} children={children} />
      ),
    },
    {
      id: 'default-light',
      title: 'Default Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={themes.light} children={children} />
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<LandingPage />} />
    {/* <Route path="/home" element={<ExampleComponent />} /> */}
    <Route path="/faq" element={<FAQsPage />} />
    <Route path="/detailed-article" element={<DetailedArticlesPage />} />
    <Route path="/information" element={<InformationPage />} />

    {/* <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} /> */}
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>

    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/rbac"
      element={
        <RequirePermission
          permission={policyEntityReadPermission}
          resourceRef="policy-entity"
        >
          <RbacPage />
        </RequirePermission>
      }
    />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />

    {/* <Route path="/home" element={<HomePage />} /> */}
    <Route
      path="/catalog"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogIndexPage />
        </RequirePermission>
      }
    />

    <Route path="/events" element={<EventsPage />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />

    <Route path="/service-request/create" element={<ServiceRequestPage />} />
    <Route path="/service-request/list" element={<ServiceRequestListPage />} />
    <Route
      path="/service-request/list/:id"
      element={<ServiceRequestDetailPage />}
    />

    <Route
      path="/service-request/list/:id/edit"
      element={<ServiceRequestEditPage />}
    />

    <Route path="/errorcatalog-list" element={<ErrorcatalogListPage />} />

    <Route path="/event-grid/list" element={<EventGridListPage />} />
    <Route path="/event-grid/create" element={<EventGridPage />} />
    <Route path="/event-grid/:id/edit" element={<EventGridEditPage />} />
    {/* <Route path="/event-grid/:id" element={<EventGridDetailPage />} /> */}

    <Route
      path="/service-catalog/create"
      element={<ServiceCatalogCreatePage />}
    />
    <Route path="/service-catalog/list" element={<ServiceCatalogListPage />} />
    <Route
      path="/service-catalog/list/detail"
      element={<ServiceRequestDetailViewPage />}
    />
    <Route path="/vendor/creation" element={<VendorCreationPage />} />
    <Route path="/system/creation" element={<SystemCreationPage />} />
    <Route path="/team-administration" element={<TeamAdministrationPage />} />
    <Route path="/community" element={<QetaPage title="Questions" />} />

    <Route path="/data-model" element={<DataModelPage />} />
    <Route path="/contact-us" element={<ContactUsPage />} />
    <Route path="/cie-overview" element={<CIEOverviewPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
