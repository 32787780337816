import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  rootRouteRef,
  dataModelRef,
  supportProcessRef,
  contactUsRef,
} from './routes';

export const documentationPlugin = createPlugin({
  id: 'documentation',
  routes: {
    root: rootRouteRef,
  },
});

export const DataModelPage = documentationPlugin.provide(
  createRoutableExtension({
    name: 'DataModelPage',
    component: () =>
      import('./components/DataModelPage').then(m => m.DataModel),
    mountPoint: dataModelRef,
  }),
);

export const CIEOverviewPage = documentationPlugin.provide(
  createRoutableExtension({
    name: 'SupportProcessPage',
    component: () =>
      import('./components/CIEOverviewPage').then(m => m.CIEOverviewPage),
    mountPoint: supportProcessRef,
  }),
);

export const ContactUsPage = documentationPlugin.provide(
  createRoutableExtension({
    name: 'ContactUsPage',
    component: () =>
      import('./components/ContactUsPage').then(m => m.ContactUs),
    mountPoint: contactUsRef,
  }),
);
