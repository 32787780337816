import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'documentation',
});

export const dataModelRef = createRouteRef({
  id: 'dataModel',
});

export const contactUsRef = createRouteRef({
  id: 'contactUs',
});

export const supportProcessRef = createRouteRef({
  id: 'supportProcess',
});
