import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { MyServiceRequestApiClient, myServiceRequestApiRef } from './api';

export const errorcatalogListPlugin = createPlugin({
  id: 'errorcatalog-list',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: myServiceRequestApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new MyServiceRequestApiClient({ discoveryApi }),
    }),
  ],
});

export const ErrorcatalogListPage = errorcatalogListPlugin.provide(
  createRoutableExtension({
    name: 'ErrorcatalogListPage',
    component: () =>
    import('./components/ErrorCatalogComponent').then(m => m.ErrorCatalogComponent),
    mountPoint: rootRouteRef,
  }),
);
